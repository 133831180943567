import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, Typography, Modal, Box, List, ListItem, Divider, Stack, IconButton } from "@mui/material";
import paradox from "./paradox.png";
import './posts.css';

const RobotInfo = ({ children }) => (
  <Box 
    className="robot-info"
    style={{ 
      padding: '16px', 
      border: '1px solid #ccc', 
      borderRadius: '8px', 
      marginBottom: '16px' 
    }}
  >
    {children}
  </Box>
);

const RobotListModal = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Link
          title="https://en.wikipedia.org/wiki/Artificial_intelligence_of_things"
          style={{
            cursor: "pointer",
            textDecoration: "none",
            paddingTop: "24px",
          }}
          onClick={() => setOpen(true)}
        >
          <Typography variant="h6" gutterBottom style={{ textAlign: "center", paddingBottom: '10px' }}>
            Click Here to see a list of the most notable humanoid robots
          </Typography>
        </Link>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          padding: "24px",
          overflow: "auto",
          display: "flex",
        }}
        className="robot-info-container"
      >
        <>
         <IconButton
            color="inherit"
            aria-label="close drawer"
            edge="start"
            onClick={() => setOpen(false)}
            style={{ position: "fixed", right: "6px", top: "0px" }}
          >
           X
          </IconButton>
<Box style={{ display: 'flex', flexDirection: "column", padding: '24px', minWidth: '400px' }}>
  <Typography
    variant="h5"
    gutterBottom
    style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
  >
    Humanoid Robots Today
  </Typography>

  <Typography variant="body1" gutterBottom>
    Here's a list of some of the most notable humanoid robots, including their versions, release dates, and descriptions:
  </Typography>
</Box>

{/* Digit */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Digit (Agility Robotics)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 2</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2019</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2023</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Estimated at around $250,000</Typography>
      <Typography variant="body2"><b>Deployment:</b> Currently in logistics and warehouse settings, with potential for expansion to more complex tasks.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A collaborative robot designed for warehouses, factories, and construction sites, featuring dexterous arms and advanced perception.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Excels in logistics with the ability to lift objects and navigate complex environments.
    </Typography>
  </Stack>
</RobotInfo>

{/* SoftBank Robotics */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      SoftBank Robotics (Pepper and NAO)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Pepper:</b></Typography>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2014</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2014</Typography>
      <Typography variant="body2"><b>Cost:</b> Approximately $20,000 to $30,000</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>NAO:</b></Typography>
      <Typography variant="body2"><b>Number of Versions:</b> 6 (Various updates and iterations)</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2006</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2018 (NAO6)</Typography>
      <Typography variant="body2"><b>Cost:</b> Approximately $10,000</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> Pepper specializes in social interaction and emotional recognition, while NAO is used in education and healthcare settings.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Pepper excels in social interaction, while NAO offers adaptability and multilingual support in classrooms and healthcare.
    </Typography>
  </Stack>
</RobotInfo>

{/* Figure */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Figure (Figure 01 and Figure 02)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 2</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2023 (Figure 01)</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> August 2024 (Figure 02)</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Aimed at industrial applications, with potential for household use.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> Figure 01 focuses on fine motor skills with highly articulated hands, while Figure 02 includes advanced AI capabilities, improved dexterity, and natural speech functions.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Figure 02 offers enhanced dexterity, movement range, and conversational abilities compared to Figure 01.
    </Typography>
  </Stack>
</RobotInfo>

{/* Apollo */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Apollo (Atronics)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2024</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2024</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Designed for industrial and possibly household automation.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A versatile humanoid robot with an energy-efficient design and modular architecture, enabling walking, running, and tool operation.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Suitable for diverse industries with its modular and customizable design.
    </Typography>
  </Stack>
</RobotInfo>

{/* Sanctuary AI */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Sanctuary AI Robot
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2023</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2023</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Potential roles include household chores, caregiving, and professional environments.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> Focused on Artificial General Intelligence (AGI), with the ability to learn from human demonstrations.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Potentially the most versatile due to its focus on AGI.
    </Typography>
  </Stack>
</RobotInfo>

{/* Unitree Robotics */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Unitree Robotics (Unitree H1)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2023</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2023</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Around $90,000 to $100,000</Typography>
      <Typography variant="body2"><b>Deployment:</b> Designed for household and office environments to improve efficiency.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> An affordable humanoid robot for daily tasks and professional settings.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Brings robotic assistance to a broader audience with advanced accessibility.
    </Typography>
  </Stack>
</RobotInfo>

{/* Robot ERA */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Robot ERA
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2020</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2020</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Ideal for flexible environments, potentially including homes and research facilities.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> Uses embodied AI for continuous learning and adaptability in dynamic environments.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Highly versatile and adaptable to different contexts.
    </Typography>
  </Stack>
</RobotInfo>

{/* Jiajia */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Jiajia (University of Science and Technology of China)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2016</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2016</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Primarily a research project, with potential for future use in social roles.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> The first humanoid robot from China, designed with a lifelike appearance and human-like interactions.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Excels in human-like interactions with a lifelike appearance and behaviors.
    </Typography>
  </Stack>
</RobotInfo>

{/* KIME */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      KIME (Macco Robotics)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2018</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2018</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Used in beverage service kiosks, with potential for expansion in hospitality.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A humanoid robotic bartender serving beverages with touchscreen ordering and a payment system.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Efficient in beverage service, dispensing up to 253 items per hour.
    </Typography>
  </Stack>
</RobotInfo>

{/* Nadine */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Nadine (Nanyang Technological University)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 1</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2015</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2015</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Used in customer service roles and as a companion robot in healthcare settings.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A social robot with realistic skin and upper body movements, designed to recognize faces, speech, and gestures.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Effective in customer service and healthcare due to its lifelike appearance.
    </Typography>
  </Stack>
</RobotInfo>

{/* OceanOne */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      OceanOne (Stanford Robotics Lab)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 2 (OceanOne and OceanOneK)</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2016 (OceanOne)</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2022 (OceanOneK)</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Mainly for marine research and underwater maintenance.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A diving humanoid robot designed for underwater exploration, equipped with haptic feedback and AI.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Optimized for deep-sea exploration and underwater tasks.
    </Typography>
  </Stack>
</RobotInfo>

{/* Promobot */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Promobot (Promobot)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 3+</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2014</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2023</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Estimated between $25,000 and $50,000</Typography>
      <Typography variant="body2"><b>Deployment:</b> Currently used in hospitality and healthcare settings, with potential for more routine tasks in homes and offices.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A customizable robot for service roles, featuring facial recognition and chat functions.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Highly adaptable, suitable for a variety of industries including hospitality and healthcare.
    </Typography>
  </Stack>
</RobotInfo>

{/* Robonaut */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      Robonaut (NASA and General Motors)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> 2 (Robonaut 1 and Robonaut 2)</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2000 (Robonaut 1)</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2011 (Robonaut 2)</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly disclosed, likely in the millions</Typography>
      <Typography variant="body2"><b>Deployment:</b> Used in space missions, with potential adaptations for hazardous environments on Earth.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> A robot designed to work alongside humans in space and on factory floors.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Optimized for space exploration, handling tasks in extreme environments.
    </Typography>
  </Stack>
</RobotInfo>

{/* RoboThespian */}
<RobotInfo>
  <Typography variant="h6" gutterBottom>
      RoboThespian (Engineered Arts)
  </Typography>
  <Divider style={{ margin: '8px 0' }} />
  <Stack direction="row" spacing={2}>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Number of Versions:</b> Multiple (with ongoing updates)</Typography>
      <Typography variant="body2"><b>First Release Date:</b> 2005</Typography>
      <Typography variant="body2"><b>Latest Version Release Date:</b> 2023 (latest iteration)</Typography>
    </Stack>
    <Stack spacing={1} flex={1}>
      <Typography variant="body2"><b>Cost:</b> Not publicly available</Typography>
      <Typography variant="body2"><b>Deployment:</b> Used in educational and entertainment settings, potentially for customer engagement and remote interaction.</Typography>
    </Stack>
  </Stack>
  <Stack spacing={1} mt={2}>
    <Typography variant="body2" gutterBottom>
      <b>Description:</b> Designed for interaction and performance, featuring telepresence software for remote communication.
    </Typography>
    <Typography variant="body2">
      <b>Outperformance:</b> Known for realistic social interactions and engaging with crowds.
    </Typography>
  </Stack>
</RobotInfo>
</>
</Modal>
</>)
}

const AIoTModal = (props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
        <Link
          title="https://en.wikipedia.org/wiki/Artificial_intelligence_of_things"
          style={{
            cursor: "pointer",
            textDecoration: "none",
            paddingTop: "24px",
          }}
          onClick={() => setOpen(true)}
        >
          <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
            {props.buttonText ??       <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
Check out this cool graphic by TSMC to learn more about AIoT and how
            it will come to affect our daily lives</div>}
          </Typography>
        </Link>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          padding: "24px",
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <img
            style={{ maxWidth: "100%" }}
            src="https://www.visualcapitalist.com/wp-content/uploads/2020/08/aiot-internet-of-things-meets-ai.jpg"
            alt="AIoT Graphic"
          />
        </Box>
      </Modal>
    </>
  );
};

const posts = [
   {
    date: "10-1-2024",
    route: "using-huggingface-autotrain",
    content: (
      <div>
        <Typography
          variant="h4"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          [Nerd Alert] Training AI Models Using Hugging Face AutoTrain: No Code Required
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          By:{" "}
          <Link
            title="https://www.linkedin.com/in/luke-nispel"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/luke-nispel/", "_blank")
            }
          >
            Luke Nispel
          </Link>{" "}
          And{" "}
          <Link
            title="https://www.chatgpt.com"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.chatgpt.com/", "_blank")
            }
          >
            ChatGPT
          </Link>{" "}
          - Oct. 1st, 2024
        </Typography>

        <Typography variant="h5" gutterBottom style={{ marginTop: "16px" }}>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          Want to train an AI model but don’t know how to code? No problem! Hugging Face’s AutoTrain feature makes it easy for anyone to train machine learning models without writing a single line of code. This tutorial will guide you step-by-step on how to use AutoTrain to create your first model.
        </Typography>
<Typography variant="body1" gutterBottom>
          Hugging Face's AutoTrain can make model training simple and easy for beginners, but it’s always helpful to understand some best practices to get the best results. In this post, we’ll go over tips for using AutoTrain effectively, including gathering quality data and setting up your project for success.
        </Typography>

        {/* Tip 1: Importance of High-Quality Data */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          1. Gather High-Quality Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Quality Over Quantity:</b> The success of your model depends heavily on the quality of your data. If you are building a sentiment analysis model, for example, ensure your dataset is representative of different types of reviews (both positive and negative). Avoid imbalanced data, where one class dominates the other, as this may lead to biased predictions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ✍️ <b>Annotation Matters:</b> If you’re labeling data manually, make sure your labels are clear and consistent. If you’re labeling text, provide examples of what constitutes each label so there is no ambiguity.
        </Typography>

        {/* Tip 2: Data Size */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          2. Balance Between Data Size and Quality
        </Typography>
        <Typography variant="body1" gutterBottom>
          📏 <b>Data Size vs. Data Quality:</b> More data can lead to better results, but if the additional data is of poor quality, it can reduce the model's accuracy. Aim for a good balance by gathering a sufficient amount of clean, diverse data, instead of just focusing on the number of entries.
        </Typography>
        <Typography variant="body1" gutterBottom>
          💡 <b>Start Small, Then Expand:</b> Start with a smaller dataset to see how well the model performs, and then gradually increase the dataset size if needed. This helps prevent wasting resources during the initial training.

        </Typography>

        {/* Tip 3: Keep Your Dataset Organized */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          3. Keep Your Dataset Organized
        </Typography>
        <Typography variant="body1" gutterBottom>
          📁 <b>Consistent File Format:</b> When using AutoTrain, make sure your dataset is in a CSV format and is consistent. Your CSV should have columns like <b>“text”</b> and <b>“label”</b> with no extra or missing entries.
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Clean Data:</b> Ensure there are no missing or incorrect labels. Extra spaces, inconsistent casing, and special characters can make your model underperform, so clean your dataset before uploading.

        </Typography>

        {/* Tip 4: Experiment with Different Model Types */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          4. Experiment with Different Model Types
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧠 <b>Try Different Model Types:</b> Hugging Face offers several model architectures through AutoTrain. Experimenting with different types can help you determine which one works best for your particular dataset. Some models are better for smaller datasets, while others perform well on larger ones.
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Track Results:</b> Make sure to keep notes of the different models and configurations you try. This will help you learn what works best for your particular use case.

        </Typography>

        {/* Tip 5: Monitor Training Metrics */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          5. Monitor Training Metrics
        </Typography>
        <Typography variant="body1" gutterBottom>
          📈 <b>Monitor Model Accuracy:</b> Keep an eye on metrics such as accuracy, precision, and recall during training. These metrics give insights into how well your model is learning and help you know if it’s underfitting or overfitting.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🔄 <b>Adjust When Needed:</b> If you see your model is overfitting (accuracy is much higher on training data than on test data), you might need to increase the dataset size or add more diverse samples.

        </Typography>

        {/* Tip 6: Choose the Right Task Type */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          6. Choose the Right Task Type
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Select the Correct Task:</b> AutoTrain supports different task types, like text classification, regression, etc. Make sure you select the right task based on your data. For example, if you are trying to classify sentiments, you should choose text classification.
        </Typography>

        {/* Tip 7: Leverage Pre-Trained Models */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          7. Leverage Pre-Trained Models
        </Typography>
        <Typography variant="body1" gutterBottom>
          🤖 <b>Use Transfer Learning:</b> One of the key advantages of Hugging Face models is that they are pre-trained on large amounts of data. This means that the models already understand language patterns to some extent, making them easier to fine-tune with your own data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>Fine-Tune Appropriately:</b> Even if you don’t have a large dataset, pre-trained models can be fine-tuned effectively on smaller, task-specific data.

        </Typography>

        {/* Tip 8: Test Your Model */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          8. Test Your Model on Real Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Evaluate on Real Examples:</b> Once your model is trained, test it on examples that it hasn't seen before. This will give you an idea of how well it generalizes to unseen data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🗣️ <b>Ask for Feedback:</b> If you’re building a model for other users, ask them to provide feedback on the results. Real-world feedback can help highlight gaps in your training data or suggest improvements.

        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px" }}
        >
          Let's Get Started with AutoTrain
        </Typography>
        {/* Step 1: Create a Hugging Face Account */}
        <Box style={{ 
          marginTop: "16px",
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
        }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            1. Create a Hugging Face Account
          </Typography>
          <Typography variant="body1" gutterBottom>
            🌐 <b>Go to Hugging Face:</b>{" "}
            <Link
              title="https://huggingface.co/"
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() => window.open("https://huggingface.co/", "_blank")}
            >
              Visit Hugging Face
            </Link>{" "}
            and click "Sign Up" in the top right corner. Fill in your details and create a free account to get started.
          </Typography>

          {/* Step 2: Access AutoTrain */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            2. Access AutoTrain
          </Typography>
          <Typography variant="body1" gutterBottom>
            🚀 <b>Navigate to AutoTrain:</b> Once logged in, click on your profile picture in the top right, then select{" "}
            <b>“AutoTrain”</b> from the dropdown. You can also go directly to{" "}
            <Link
              title="https://huggingface.co/autotrain"
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() =>
                window.open("https://huggingface.co/autotrain", "_blank")
              }
            >
              AutoTrain
            </Link>
            .
          </Typography>

          {/* Step 3: Start a New Project */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            3. Start a New AutoTrain Project
          </Typography>
          <Typography variant="body1" gutterBottom>
            📝 <b>Create a Project:</b> Click the{" "}
            <b>“New AutoTrain Project”</b> button. You’ll be prompted to enter details like the project name and type.
            <ul>
              <li>
                ✨ <b>Project Name:</b> Choose a name (e.g., “Sentiment Analysis on Product Reviews”).
              </li>
              <li>
                📁 <b>Type of Project:</b> Select the task type, like Text Classification.
              </li>
            </ul>
          </Typography>

          {/* Step 4: Upload Your Dataset */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            4. Upload Your Dataset
          </Typography>
          <Typography variant="body1" gutterBottom>
            📊 <b>Prepare a CSV:</b> You’ll need a dataset in CSV format. The file should contain:
            <ul>
              <li>
                A <b>“text”</b> column with the content you want the model to analyze.
              </li>
              <li>
                A <b>“label”</b> column containing labels (e.g., “positive” or “negative”).
              </li>
            </ul>
            Click on <b>“Upload Dataset”</b> and select your CSV file to upload.
          </Typography>

          {/* Step 5: Configure Training Settings */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            5. Configure Training Settings
          </Typography>
          <Typography variant="body1" gutterBottom>
            ⚙️ <b>Training Settings:</b> 
            <ul>
              <li>
                🔍 <b>Model Type:</b> Select the model type you want to use. You can leave this as the default option if you’re unsure.
              </li>
              <li>
                💰 <b>Compute and Budget:</b> Set the maximum training time or credits to use for training.
              </li>
            </ul>
            The default settings are good to start with, so you don’t need to change anything unless you want to.
          </Typography>

          {/* Step 6: Start Training */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            6. Start Training
          </Typography>
          <Typography variant="body1" gutterBottom>
            🟢 <b>Click “Start Training”:</b> AutoTrain will handle the rest—data preparation, training, and evaluation.
            <br />
            This step may take some time, depending on your dataset and the settings you chose.
          </Typography>

          {/* Step 7: Monitor Your Model */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            7. Monitor Your Model
          </Typography>
          <Typography variant="body1" gutterBottom>
            📈 <b>Progress Dashboard:</b> You can view the progress of your model training from the project page. Here, you’ll see metrics like accuracy and loss.
          </Typography>

          {/* Step 8: Evaluate and Deploy */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            8. Evaluate and Deploy
          </Typography>
          <Typography variant="body1" gutterBottom>
            🎉 <b>Evaluate:</b> Once training is complete, you can test the model from within the interface.
            <br />
            🌐 <b>Deploy:</b> Click <b>“Deploy”</b> to make your model available via an API. This way, you can use it in applications or connect it with other tools.
          </Typography>

          {/* Step 9: Share Your Model */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            9. Share Your Model (Optional)
          </Typography>
          <Typography variant="body1" gutterBottom>
            🤝 <b>Community Sharing:</b> If you’d like, you can share your model on the Hugging Face Hub so others can use or build upon it.
          </Typography>

          {/* Step 10: Use Your Model */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
          >
            10. Use Your Model
          </Typography>
          <Typography variant="body1" gutterBottom>
            📡 <b>API Access:</b> Hugging Face provides an API key that you can use to integrate your model into applications—without writing any code!
          </Typography>

          <Box
            sx={{
              backgroundColor: "#f0f4fc",
              border: "1px solid #d0d9e8",
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
          >
            <Typography variant="body1" gutterBottom>
              🚀 <b>Final Thoughts:</b> AutoTrain makes AI model training accessible for everyone. Whether you’re analyzing customer reviews or building a sentiment analysis tool, AutoTrain takes care of the heavy lifting—all without needing any coding skills.
            </Typography>
          </Box>
        </Box>
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          Conclusion
        </Typography>
        <Typography variant="body1" gutterBottom>
          With Hugging Face AutoTrain, creating and training your own AI model is just a few clicks away. This tool is perfect for those who want to get started in machine learning without the complexities of coding. Try it out today and start building your own intelligent applications!
        </Typography>
      </div>
    ),
  },
  {
    date: "9-25-2024",
    route: "ai-learning-methods",
    content: (
      <div>
        <Typography
          variant="h4"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          [Research] AI Learning Methods: From Supervised to Self-Supervised
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          By:{" "}
          <Link
            title="https://www.linkedin.com/in/luke-nispel"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/luke-nispel/", "_blank")
            }
          >
            Luke Nispel
          </Link>{" "}
          And{" "}
          <Link
            title="https://www.chatgpt.com"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.chatgpt.com/", "_blank")
            }
          >
            ChatGPT
          </Link>{" "}
          - Sept. 25th, 2024
        </Typography>

        <Typography variant="h5" gutterBottom style={{ marginTop: "16px" }}>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the field of artificial intelligence (AI) and machine learning (ML), different learning methods have evolved to address a wide array of challenges. In this post, we’ll explore six primary learning methods: Supervised, Unsupervised, Reinforcement, Semi-supervised, Transfer, and Self-supervised learning. Each of these methods has unique applications, and we’ll look at their historical context, frequency of use, and future potential, particularly in robotics.
        </Typography>

        {/* Supervised Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          1. Supervised Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Supervised learning was first developed in the 1950s-60s during research on perceptrons and linear classifiers by Frank Rosenblatt.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> The model is trained using labeled data, learning to map inputs to outputs by minimizing the difference between predictions and actual labels.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> Supervised learning is widely used for tasks like image classification, spam detection, and medical diagnostics.{' '}
          <Link 
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            title="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10312208/"
            to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10312208/'
          >
            For example, detecting tumors in medical scans is a common application.
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> This method is the most frequently used due to the availability of labeled datasets and the variety of tasks it can address.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> Supervised learning has great potential in robotics, particularly in areas like robotic vision and grasping tasks, where labeled datasets help robots learn to recognize and interact with their environment. In the future, we might see advancements in automated labeling techniques, enabling robots to continuously learn from real-world environments without human intervention. As robotic systems become more adept at perceiving and responding to complex environments, supervised learning will play a key role in enhancing their decision-making capabilities.
          </Typography>
        </Box>

        {/* Unsupervised Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          2. Unsupervised Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Unsupervised learning began in the 1960s, with the development of clustering algorithms like k-means.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> The model identifies hidden structures in unlabeled data, clustering or reducing dimensionality to discover underlying patterns.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> It is often used for customer segmentation, anomaly detection, and data exploration,{' '}
          <Link 
            to='https://abmatic.ai/blog/behavioral-segmentation-how-to-group-customers-based-on-their-actions'
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            title="https://abmatic.ai/blog/behavioral-segmentation-how-to-group-customers-based-on-their-actions"
          >
            like grouping customers based on purchasing behavior.
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> Unsupervised learning is frequently used, especially when labeling data is expensive or impractical.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> In robotics, unsupervised learning could allow robots to autonomously group and categorize data from their environment. This capability might lead to robots improving their own pattern recognition for tasks like navigating new spaces or detecting anomalies in their surroundings, which would enhance adaptability in dynamic environments.
          </Typography>
        </Box>

        {/* Reinforcement Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          3. Reinforcement Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Reinforcement learning was formalized in the 1980s through algorithms like Temporal Difference (TD) learning and Q-learning.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> The model interacts with an environment and learns to make decisions by maximizing rewards and minimizing penalties.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> Used in robotics and gaming, reinforcement learning trains agents to make sequential decisions, like navigating spaces or playing games like{' '}
          <Link 
            to='https://deepmind.google/technologies/alphago/'
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            title="https://deepmind.google/technologies/alphago/"
          >
            AlphaGo.
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> It is moderately used due to its complexity and computational demands, but essential for dynamic decision-making systems.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> Reinforcement learning could lead to advancements in robotic autonomy, allowing robots to learn from their own actions and environments. This would be crucial in tasks like autonomous navigation, where robots must adapt to real-time feedback in complex and changing surroundings, such as in self-driving cars or industrial automation.
          </Typography>
        </Box>

        {/* Semi-Supervised Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          4. Semi-Supervised Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Semi-supervised learning emerged in the 1990s as a way to use both labeled and unlabeled data effectively.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> A small labeled dataset is combined with a large unlabeled dataset to improve the model's learning efficiency.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> Used in text classification and bioinformatics, it’s particularly useful when{' '}
          <Link 
            to='https://link.springer.com/article/10.1007/s10462-023-10393-8'
            title='https://link.springer.com/article/10.1007/s10462-023-10393-8'
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            labeling data is expensive or difficult.
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> Less frequently used, but powerful in cases with a mix of labeled and unlabeled data.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> Semi-supervised learning could allow robots to leverage large amounts of unlabeled sensory data while only requiring minimal human intervention for labeling. This is especially useful in situations where fully labeled datasets are unavailable or too costly to generate, improving efficiency in robotic perception and learning tasks.
          </Typography>
        </Box>

        {/* Transfer Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          5. Transfer Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Transfer learning gained traction in the 1990s-2000s, allowing models to adapt knowledge from one task to another.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> A model pre-trained on one task is fine-tuned for a related task, reducing the amount of data and time needed for training.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> Transfer learning is commonly used in image recognition and NLP, such as{' '}
          <Link
            to='https://bmcmedimaging.biomedcentral.com/articles/10.1186/s12880-022-00793-7'
            title='https://bmcmedimaging.biomedcentral.com/articles/10.1186/s12880-022-00793-7'
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            fine-tuning a model trained on ImageNet for a medical task.
          </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> Moderately used, it’s popular for adapting pre-trained models to new domains with limited data.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> Transfer learning can accelerate robotic learning by applying pre-trained models to new tasks, significantly reducing the time needed to teach robots how to perform similar tasks in new environments. This could allow for greater flexibility in deploying robots across multiple industries.
          </Typography>
        </Box>

        {/* Self-Supervised Learning */}
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          6. Self-Supervised Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          🧪 <b>Discovery:</b> Self-supervised learning emerged in the 2010s and gained prominence with models like BERT and GPT.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ⚙️ <b>How it Works:</b> The model generates its own labels from the input data, allowing it to learn from vast amounts of unlabeled data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          🎯 <b>Applications:</b> Mainly used in natural language processing (NLP), self-supervised learning powers models like GPT (which the one that helped write this post), predicting the next word in a sequence.
        </Typography>
        <Typography variant="body1" gutterBottom>
          📊 <b>Frequency of Use:</b> Less frequent but growing rapidly, especially for training large-scale AI models where labeled data is scarce.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f4fc",
            border: "1px solid #d0d9e8",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Typography variant="body1" gutterBottom>
            🚀 <b>Future Potential (Robotics Focus):</b> Self-supervised learning could be a game-changer for robotics by allowing robots to generate their own training data in real-time, learning continuously from their environment. This could unlock greater autonomy for robots in dynamic and unpredictable settings, such as exploring new terrains or environments without prior training data.
          </Typography>
        </Box>

        <Typography variant='h6' style={{ marginTop: '16px' }}>
          Conclusion
        </Typography>
        <Typography variant='body1' gutterBottom>
          As AI continues to push the boundaries of innovation, the learning methods we’ve explored—Supervised, Unsupervised, Reinforcement, Semi-Supervised, Transfer, and Self-Supervised—play crucial roles in shaping the future of robotics and machine learning. While some methods, like supervised and unsupervised learning, are widely adopted in real-world applications today, others like reinforcement and self-supervised learning hold the potential to unlock even greater advancements, particularly in autonomous systems and adaptive environments.
        </Typography>
        <Typography variant='body1' gutterBottom>
          In robotics, these learning methods are critical to enhancing capabilities like perception, decision-making, and adaptability. From teaching robots how to grasp objects in unstructured environments to enabling them to autonomously explore new terrains, each learning method offers unique advantages for addressing complex, real-world challenges.
        </Typography>
        <Typography variant='body1' gutterBottom>
          Looking ahead, the integration of self-supervised and reinforcement learning could lead to groundbreaking autonomy in robotics—where machines continuously learn and improve without human intervention. The exciting future of AI-driven robotics will undoubtedly rely on the ongoing evolution and combination of these powerful learning techniques.
        </Typography>
        <Typography variant='body1' gutterBottom>
          Whether it’s robots flipping burgers or autonomous cars navigating our streets, the learning methods powering these machines are transforming how we interact with technology—and the possibilities are just beginning.
        </Typography>
      </div>
    ),
  },
  {
    date: "9-15-2024",
    route: "ai-robotics-advancements",
    content: (
      <div>
        <Typography
          variant="h4"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          [Research] Robotics in the AI Revolution: Latest Advancements, Trends, and Future Predictions
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          By:{" "}
          <Link
            title="https://www.linkedin.com/in/luke-nispel"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/luke-nispel/", "_blank")
            }
          >
            Luke Nispel
          </Link>{" "}And{" "}
          <Link
            title="https://www.chatgpt.com"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() =>
              window.open("https://www.chatgpt.com/", "_blank")
            }
          >ChatGPT</Link>{" "}
          - Sept. 17th, 2024
        </Typography>

        {/* Nerd News Section */}
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
         🤯 Nerd News 🧠
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              <b><Link
                title="https://www.theverge.com/2024/9/9/24239903/amazon-audible-audiobook-narrators-ai-generated-voice-clones"
                onClick={() =>
                  window.open(
                    "https://www.theverge.com/2024/9/9/24239903/amazon-audible-audiobook-narrators-ai-generated-voice-clones",
                    "_blank"
                  )
                }
              >Audible AI Narrators:</Link></b> Amazon's Audible is inviting select
              narrators to create AI voice models, offering a new way for audiobook creators to earn royalties.{" "}
            </li>
            <li>
              <b><Link
                title="https://www.livescience.com/technology/artificial-intelligence/common-ai-models-believe-racist-stereotypes-about-african-americans-that-predate-the-civil-rights-movement-and-they-try-to-hide-it-when-confronted"
                onClick={() =>
                  window.open(
                    "https://www.livescience.com/technology/artificial-intelligence/common-ai-models-believe-racist-stereotypes-about-african-americans-that-predate-the-civil-rights-movement-and-they-try-to-hide-it-when-confronted",
                    "_blank"
                  )
                }
              >AI Racial Bias:</Link></b> Studies show that some AI models are still picking up on and reinforcing racist stereotypes against Black people. And it gets worse—they’re actually trying to hide this bias when confronted.{" "}
            </li>
            <li>
              <b>  <Link
                title="https://www.youtube.com/watch?v=puEgugluadk"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=puEgugluadk",
                    "_blank"
                  )
                }
              >Adobe’s AI in Video Editing:</Link>
</b> Adobe plans to introduce AI-generated video features in its editing software by the end of the year, making it easier for video editors to enhance their projects.{" "}
            </li>
            <li>
              <b> <Link
                title="https://www.techopedia.com/news/apple-intelligence-release-date"
                onClick={() =>
                  window.open(
                    "https://www.techopedia.com/news/apple-intelligence-release-date",
                    "_blank"
                  )
                }
              >Apple Intelligence Rollout:</Link></b>Apple just announced a new AI system, "Apple Intelligence," set to roll out on iOS, iPadOS, and macOS next month.{" "}
            </li>
          </ul>
        </Typography>

        {/* Main Content Section */}
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          Artificial Intelligence (AI) and robotics have been evolving rapidly, bringing forth a wave of advancements that are reshaping industries and everyday life. In this post, we’ll explore some of the most exciting developments from the past year, discuss current trends, and take a glimpse into the future.
        </Typography>

         {/* Main Content Section */}
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          Latest Advances
        </Typography>
        <Typography variant="body1" gutterBottom>
          Over the past year, robots have become much more intelligent and
          capable. For instance, <Link onClick={() => { 
            window.open('https://www.starship.xyz/', '_blank')
          }}>companies are now using autonomous delivery
          robots</Link> that can safely navigate city streets to deliver packages,
          showcasing how AI-powered navigation is becoming more reliable and
          practical. In healthcare, <Link onClick={() => {
            window.open('https://www.intuitive.com/en-us', '_blank')
          }}>AI-driven surgical robots are assisting
          doctors</Link>, allowing for more precise and less invasive surgeries. These
          advancements show how AI and robotics are starting to work hand-in-hand
          to improve safety and efficiency in various fields.
        </Typography>
        <img src="https://fertilityandmidwifery.com/wp-content/uploads/2021/07/surgical_1200x736_davinci.jpg" alt="AI-driven surgical robot" style={{ width: '100%', marginTop: '16px'}} />
        <Typography variant="body1" gutterBottom>
          We’ve previously explored the concept of the "Artificial Intelligence
          of Things" (AIoT) – the merging of AI's decision-making capabilities
          with the interconnected nature of IoT devices. In that post, I talked
          about how the Internet of Things (IoT) grew to encompass billions of
          devices, but it’s through the integration of AI that these devices
          truly become “smart.” By combining AI technologies with IoT
          infrastructure, devices like robot vacuum cleaners not only detect
          obstacles but also learn and optimize their actions over time. This
          evolution brings us to a new frontier: the role of AIoT in advancing
          robotics.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Building on that foundation, AIoT is now driving robots to become more
          than just standalone machines. Instead, they’re becoming part of a
          larger, interconnected network. 
            <AIoTModal buttonText='Imagine a smart home equipped with
          AIoT sensors that monitor lighting, temperature, and movement patterns.'/>
          The data collected by these sensors isn’t just limited to the home’s
          management – it can be used by robots within the home to navigate more
          efficiently, decide when to vacuum, or even provide caregiving by
          detecting when someone might need help.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The implications extend beyond smart homes. In industrial settings,
          AIoT allows robots to work more effectively alongside humans by
          providing real-time data about machine operations, safety conditions,
          and environmental factors. For example, factory robots connected
          through AIoT can communicate with other machinery and sensors on the
          production floor, dynamically adjusting their actions to maintain
          safety and efficiency.
        </Typography>
        <Typography variant="body1" gutterBottom>
          But it doesn’t stop there. AIoT is transforming how robots interact
          with the world at large. For instance, self-driving cars are a prime
          example of robots that use AIoT to operate autonomously and safely.
          They don’t just rely on their internal sensors; they connect to an
          extensive network of information – from traffic lights and weather
          stations to other vehicles on the road. This interconnected system
          provides a constant stream of data, allowing the car to make
          context-aware decisions in real-time. The AIoT network empowers the car
          to understand its environment beyond what its built-in sensors can
          detect, resulting in safer and more efficient navigation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This growing integration of AIoT into robotics is what makes robots
          increasingly adaptive and responsive. By tapping into a broader network
          of data, they can make more informed decisions and optimize their
          actions, whether it’s in a smart home, a factory, or on the streets.
          This level of connectivity is what truly unleashes the power of AIoT in
          the world of robotics.
        </Typography>

        <img src='https://ifr.org/img/uploads/Teaser_PM_Robot_Trends_2024_1600x900.png' alt='Top 5 Robot Trends 2024' style={{ width: '100%', marginTop: '16px'}} />
         <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          Latest Trends in Robotics and AI
        </Typography>
        <Typography variant="body1" gutterBottom>
          Robotics is evolving with the integration of AI, leading to new capabilities. One notable trend is the use of 
          <b> AI and machine learning</b> to enhance robot intelligence. Generative AI simplifies robot programming, allowing natural language commands instead of complex coding. Predictive AI optimizes robot maintenance by analyzing performance data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>
            <Link
              title="Collaborative Robots - Wikipedia"
              onClick={() => window.open('https://en.wikipedia.org/wiki/Cobot', '_blank')}
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              Collaborative robots
            </Link>
          </b> (cobots) are gaining traction. Equipped with advanced sensors and smart grippers, cobots safely work alongside humans, assisting with repetitive tasks and operating in hazardous environments.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>
            <Link
              title="Mobile Manipulators - Wikipedia"
              onClick={() => window.open('https://en.wikipedia.org/wiki/Mobile_manipulator', '_blank')}
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              Mobile manipulators
            </Link>
          </b> (MoMas) are becoming popular as well. These robots combine mobile platforms with robotic arms to automate tasks in industries like logistics and automotive, navigating complex environments to handle materials efficiently.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>
            <Link
              title="Digital Twins - Wikipedia"
              onClick={() => window.open('https://en.wikipedia.org/wiki/Digital_twin', '_blank')}
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              Digital twins
            </Link>
          </b> are changing robot development. These virtual replicas of physical systems use real-world data to run simulations, optimizing robot performance while minimizing risks and costs.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Lastly, 
            <b>
              <Link
                title="Humanoid Robot - Wikipedia"
                onClick={() => window.open('https://en.wikipedia.org/wiki/Humanoid_robot', '_blank')}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                {' '}humanoid robots
              </Link>
            </b> are on the rise. Designed to operate in human-centric environments, humanoids are being developed for various tasks. China has set ambitious goals to mass-produce these robots by 2025, potentially transforming industries and daily life. 
        </Typography>
        <Typography variant="body1" gutterBottom>
          These trends demonstrate the convergence of AI and robotics, shaping the future of automation and the workplace.
          <Link
            title="Top 5 Robot Trends 2024 - IFR"
            onClick={() => window.open('https://ifr.org/ifr-press-releases/news/top-5-robot-trends-2024', '_blank')}
          >
            [Source]
          </Link>
        </Typography>
      
        <RobotListModal />

        <Box marginTop='16px' mb={4} p={2} border={1} borderRadius={2} borderColor="grey.400">
          <Typography variant="h6" gutterBottom>
            Mobile Manipulators and Mobile ALOHA
          </Typography>
          <Divider style={{ margin: '8px 0' }} />
          <Stack spacing={1} mt={2}>
            <Typography variant="body2" gutterBottom>
              Mobile manipulators, also known as <i>MoMas</i>, are robots that combine mobility with robotic arms, allowing them to perform complex tasks in dynamic environments. Recently, Stanford University introduced an exciting example of this technology: <b>Mobile ALOHA</b>. This robot has been designed to carry out a variety of household chores, including vacuuming, sautéing shrimp, doing laundry, and watering plants.
            </Typography>
            <Typography variant="body2" gutterBottom>
              The key to Mobile ALOHA's success lies in its imitation learning approach. The researchers use a teleoperation system, puppeteering the robot through desired actions about 50 times. Through these demonstrations, Mobile ALOHA learns to perform tasks autonomously. This method showcases the "Mobile Aloha" philosophy, where robots not only complete tasks but do so in harmony with their surroundings and the humans they interact with.
            </Typography>
            <Typography variant="body2" gutterBottom>
              By using AI and whole-body control, mobile manipulators like Mobile ALOHA navigate complex environments and adapt to various situations. They embody the principle of working cooperatively within human-centric spaces, aligning perfectly with the idea of seamless integration and collaboration. This change is transforming how we view automation, as it emphasizes not just efficiency, but also the quality of interaction between robots and humans.
            </Typography>
            <Typography variant="body2">
              In the future, we can expect mobile manipulators to further embrace the Mobile Aloha philosophy, becoming more adept at smoothly blending into our daily lives, whether in homes, hospitals, or industrial settings. If you’re interested in learning more about this groundbreaking project, check out the <Link href="https://mobile-aloha.github.io/">Mobile ALOHA project</Link> and <Link href="https://news.stanford.edu/stories/2024/04/mobile-aloha-robot-is-a-quick-learner">Stanford's report</Link> for more details.
            </Typography>
          </Stack>
        </Box>

        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          Future Predictions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Looking ahead, one of the most exciting prospects is the development of autonomous and self-learning robots. Future robots will be able to adapt to new environments and tasks without human intervention, much like how a human learns on the job. Imagine a robot that can move around a warehouse, identify items, and pack them for shipping all on its own – this is the kind of autonomy that’s on the horizon.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We also expect robots to collaborate even more closely with humans. In fields like healthcare, agriculture, and customer service, AI-driven robots will act as assistants, making jobs easier and more efficient rather than replacing workers. Think of a robot nurse that can support doctors by performing routine tasks, allowing medical staff to focus on more critical patient care.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The growth of AIoT is set to continue, leading to even more interconnected and intelligent systems. From smart cities managing traffic flow to farms using sensors and AI to monitor crops, we’re moving toward a world where data-driven insights and automation will become the norm. Adobe's plan to introduce AI-generated video editing features is a small glimpse into this future of automated creativity.{" "}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Finally, advancements in natural language processing will make robots better communicators. As they become more adept at understanding human speech, they’ll be able to follow complex instructions and hold natural conversations. This development could pave the way for personal robots that understand and respond to your daily needs seamlessly.
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: "16px", borderBottom: "black 1px solid" }}
        >
          Conclusion
        </Typography>
        <Typography variant="body1" gutterBottom>
          The rapid developments in AI and robotics indicate a future where intelligent machines will significantly impact our daily lives. While there are still challenges to address, like ethical concerns and potential biases, the benefits and possibilities are vast. The world of AI and robotics is evolving quickly, so keep an eye out for these innovations as they continue to shape our future.
        </Typography>
      </div>
    ),
  },
  {
    date: '9-10-2024',
    route: 'ai-today',
    content: (
      <div>
        <Typography variant="h4" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
          [Research] AI Today
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          By:  <Link 
          title='https://www.linkedin.com/in/luke-nispel' 
          style={{
            cursor: 'pointer',
            textDecoration: 'none'
          }}
          onClick={() => window.open('https://www.linkedin.com/in/luke-nispel/', '_blank')}>
            Luke Nispel
          </Link> - Sept. 10th, 2024 
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          I will be approaching this project as I would a project for work, which involves both research & development. These first few posts will lean towards research but I will denote that before the post name so that if you are not interested in research, you can ignore these.
        </Typography>
        <Typography variant="body1" gutterBottom>
          I also write these posts after my working hours and I could not do it without my cup of coffee from 
          <Link 
            title='https://cityheightsba.org/business-spotlight-ryan-bros-coffee' 
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              margin:'4px'
            }}
            onClick={() => window.open('https://cityheightsba.org/business-spotlight-ryan-bros-coffee', '_blank')}>
              Ryan Bros Coffee City Heights
          </Link>here in San Diego, CA. Check them out if you get a chance.
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
            Disclaimer
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the AI & robotics space, I am a hobbyist at best, not a professional. I have built my career by being an internet sleuth, a stack overflow junkie. I do not believe that I have all the right sources but I will do my best to pull the right ones together. If someone is reading this and has better insight into these topics, please feel free to reach out and let me know what I can improve on. I am here to learn as well. 
        </Typography>
        <Typography variant="body1" gutterBottom>
          One thing I have seen enough in my career is that, at all levels, there is a common feeling of not being enough or being an imposter in the role someone has worked much of their life to get into. I believe this keeps many people from trying new things or adventuring in these more unknown or complex spaces. We are here to break past feeling like an imposter and to become a creator.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Before now, I did not know much about AI and Machine Learning beyond how things work on a high level. I have used chatbots at work for productivity and features on on some apps now. Taken a course over here or read an article over there.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In fact, I used Adobe Image Generation to make the logo of Flip-The-Burger. I have definitely seen the positive results from leveraging it first hand. But I want to take my understanding of where the technology is at today a step further. 
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you first want to also learn about AI on a high-level, I recommend checking out these videos below:
          <br/>
          <Typography variant="subtitle1" style={{ marginTop: '16px'}}>
            <b>The AI Breakdown</b>
          </Typography> 
          <br/>
          <div class='iframe-container' style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/qYNweeDHiyU?si=J5aLha8KFCAP9bbV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>          
          </div>
          <br/>
          <Typography variant="subtitle1" style={{ marginTop: '16px'}}>
            <b>How AIs, like ChatGPT, Learn</b>
          </Typography>
          <br/>
          <div class='iframe-container' style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/R9OHn5ZF4Uo?si=FoGPCEPd3D9hgksI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>          
          </div>
          <br/>
          <Typography variant="subtitle1" style={{ marginTop: '16px'}}>
            <b>AI explained by an AI</b>
          </Typography> 
          <br/>
          <div class='iframe-container' style={{ display: 'flex', justifyContent: 'center' }}>
          <iframe class="responsive-iframe" src="https://www.youtube.com/embed/9DLDCMNw2yw?si=ozbtEZX0XNCla6TU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>          </div>
        </Typography>
        <Typography variant="body1" gutterBottom style={{ marginTop: '24px'}}>
           Here is some of what I pulled together as I dug deeper into this topic. 
        </Typography>
        <Typography variant="h6" gutterBottom style={{ margin: '16px 0', borderBottom: 'black 1px solid'}}>
            Moravec's Paradox
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ maxWidth: '500px', width: '70%' }} src='https://i.kym-cdn.com/entries/icons/original/000/023/397/C-658VsXoAo3ovC.jpg' />
        </div>
        <Typography style={{ paddingTop: '16px' }} variant="body1" gutterBottom>
        The principle was articulated by a group of researchers in the 1980s. The paradox is essentially that computers are smart enough to beat adult humans at reasoning-based tasks like chess but too dumb to match the sensory and perception intelligence of a toddler.
          I have a screenshot below of the <Link title="https://en.wikipedia.org/wiki/Moravec's_paradox" onClick={() => window.open("https://en.wikipedia.org/wiki/Moravec's_paradox", '_blank')} style={{
                  cursor: 'pointer',
                  textDecoration: 'none'
              }}>Wikipedia page</Link> for Moravec's Paradox but two main ideas stand out:
        </Typography>
        <Typography variant="body2" gutterBottom>
          <ul style={{ paddingInlineStart: '16px' }}>
            <li><b>Huge Computation Cost:</b>
              <ul style={{ paddingInlineStart: '16px' }}>
                <li>Computers first managed to conquer tasks based in reasoning. Looking up information and calculations were some of the first uses of computers.</li>
                <li>Reasoning requires very little computation while sensorimotor and perceptions skills require an enormous amount more.</li>
                <li>In 1988, Moravec said, It is "difficult or impossible to give them the skills of a one-year-old when it comes to perception and mobility".</li>
                <li>Steven Pinker in 1994 wrote, "The main lesson of 24 years of AI research is that the hard problems are easy and the easy problems are hard".</li>
              </ul>
            </li>
          <li style={{ paddingTop: '16px' }}><b>Paradox Paradigm Closing In:</b>
            <ul style={{ paddingInlineStart: '16px' }}>
              <li>By the 2020's, computers have grown to be hundreds of millions times faster than they were in the 1970's, finally unlocking the computational power needed to handle sensory and perception skills, as Moravec had predicted in 1976.</li>
              <li>In 2017, leading machine learning research, Andrew Ng, said, "Almost anything it takes a typical human can do in less than one second of mental thought, we can probably now or in the near future automate."</li>
              <li>By the 2030's, Moravec's paradox will likely be a thing of the past</li>
            </ul>
          </li>
          </ul>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '16px none' }}>
          <img style={{ width: '100%', maxWidth: '500px' }} src={paradox} />
        </div>
        </Typography>
        <Typography variant="h6" gutterBottom style={{ margin: '16px 0', borderBottom: 'black 1px solid'}}>
          <Link 
            title='https://openai.com/index/sora/' 
            style={{
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            onClick={() => window.open('https://openai.com/index/sora/', '_blank')}>
              Sora, by OpenAI
          </Link>
        </Typography>
        <Typography variant="body2" gutterBottom>
          Watch this video on Sora to get an idea of how good image and video generation has become. 
          <div class='iframe-container' style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/NXpdyAWLDas?si=d97N6O1UNuBTFFKb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </Typography>
        <Typography variant="h6" gutterBottom style={{ margin: '16px 0', borderBottom: 'black 1px solid'}}>
         <Link 
          title='https://aiindex.stanford.edu/' 
          style={{
            cursor: 'pointer',
            textDecoration: 'none'
          }}
          onClick={() => window.open('https://aiindex.stanford.edu/', '_blank')}>
            Stanford's AI Index
          </Link>
        </Typography>
        <Typography style={{ paddingTop: '16px' }} variant="body1" gutterBottom>
         First released in 2017, Stanford's AI Index consistently comes up as a voice in the AI space for communicating latest trends.
         <br/>
         <Link 
          title='https://aiindex.stanford.edu/report/' 
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            margin: '16px',
            marginTop: '24px',
            fontSize: '24px',
            textAlign: 'center'
          }}
          onClick={() => window.open('https://aiindex.stanford.edu/report/', '_blank')}>
            Stanford's 2024 AI Index Report
        </Link>
        Points that stood out to me:
        <List>
        <ListItem>1. AI beats humans on some tasks, but not on all.</ListItem>
        <ul>
          <li>Excelled at image classification, visual reasoning, and English understanding.</li>
        </ul>
        <ul>
          <li>Trailed behind humans with competition-level mathematics, visual commonsense reasoning and planning.</li>
        </ul>
        <ListItem>2. AI has made workers more productive and shows using it leads to higher quality work and potentially closes some of the skill gap between workers.</ListItem>
        <ListItem>3. Industry is leading AI development but there are not standards on how to report that work so there are complications on how to compare risks and limitations between AIs produced by different groups.</ListItem>
        <ListItem>4. Huge increase in regulation around AI in the US. In 2023, there were 25 AI-related regulations, up from just one in 2016. Last year alone, the total number of AI-related regulations grew by 56.3%.</ListItem>
        <ListItem>5. People are more aware of AI's potential impact and are also more nervous.
        I hope these videos can help people become more comfortable with AI and see how we can build upon the work that AI is going to be doing for us.</ListItem>
        </List>
        <br/>
        <Typography variant="h6" gutterBottom style={{ margin: '16px 0', borderBottom: 'black 1px solid'}}>
            Internet of Things (IoT) to Artificial Intelligence of Things (AIoT) 
        </Typography>
        <Typography variant="body1" gutterBottom>

        Defining the  <Link 
          title='https://en.wikipedia.org/wiki/Internet_of_things' 
          style={{
            cursor: 'pointer',
            textDecoration: 'none'
          }}
          onClick={() => window.open('https://en.wikipedia.org/wiki/Internet_of_things', '_blank')}>
            Internet of Things (IoT)
        </Link> as "simply the point in time when more 'things or objects' were connected to the Internet than people", <Link 
              title='https://www.cisco.com/c/dam/en_us/about/ac79/docs/innov/IoT_IBSG_0411FINAL.pdf' 
              style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}
              onClick={() => window.open('https://www.cisco.com/c/dam/en_us/about/ac79/docs/innov/IoT_IBSG_0411FINAL.pdf', '_blank')}>
                Cisco Systems estimated
              </Link> that the IoT was "born" between 2008 and 2009, with the things/people ratio growing from 0.08 in 2003 to 1.84 in 2010.
              </Typography>
              <Typography variant="body1" gutterBottom>
              In 2011,  <Link 
              title='https://www.statista.com/statistics/1101442/iot-number-of-connected-devices-worldwide/' 
              style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}
              onClick={() => window.open('https://www.statista.com/statistics/1101442/iot-number-of-connected-devices-worldwide/', '_blank')}>
                according to Statista
              </Link>, the IoT was made of 1 billion connections for the first time. Since then, the number of connections has grown to nearly 25 billion, with projections to add over 5 billion more connections by the end of the decade.
              </Typography>
        <Typography variant="body1" gutterBottom>
        With AI and the IoT growing in tandem at exponential rates, its only natural to consider how they will impact and grow together.
        Here is an excerpt from an article by Ingo Willems:<Link 
        title='https://dmexco.com/stories/thats-why-the-internet-of-things-needs-artificial-intelligence/' 
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          paddingLeft: '4px'
        }}
        onClick={() => window.open('https://dmexco.com/stories/thats-why-the-internet-of-things-needs-artificial-intelligence/', '_blank')}>
          Why The Internet of Things needs AI
        </Link>
        </Typography>
        <Typography variant="subtitle2" gutterBottom style={{ padding: '24px'}}>
         Users manage and monitor connected things remotely, but they only become truly “smart” when AI is implemented. Smart devices can learn from their own activity as well as from each other and continue to evolve in this way – which means that smart devices are increasingly able to make their own decisions.
         <div style={{ marginTop: '8px'}}/>
         An example from the private sector is the robot vacuum cleaner. It is connected to the Internet and uses sensors to detect or avoid obstacles in the home. By using its AI or networking into the smart home, the machine is able to learn and remember where there are obstacles, which routes have already been covered, and which still have to be cleaned. The AI device directs itself and works more and more independently and safely through continuous self-optimization.
        </Typography>

        <Typography variant="body1" gutterBottom>
          This brings us to the concept of: <Link 
          title='https://en.wikipedia.org/wiki/Artificial_intelligence_of_things' 
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            padding: '0 4px'
          }}
          onClick={() => window.open('https://en.wikipedia.org/wiki/Artificial_intelligence_of_things', '_blank')}>
              The Artificial Intelligence of Things (AIoT).
        </Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          It is the combination of artificial intelligence (AI) technologies with the Internet of things (IoT) infrastructure. Each thing on the internet could have its own built-in AI system, to achieve more efficient IoT operations, improve human-machine interactions and enhance data management and analytics, within itself and with other things on the internet. This creates an explosion of possibilities. 
          <br/>
          <AIoTModal />
        </Typography>
        <Typography variant="h6" gutterBottom style={{ margin: '16px 0', borderBottom: 'black 1px solid'}}>
          Conclusion
        </Typography>
        <Typography variant="body1" gutterBottom>
          From the 1970's to now, AI and computers as a whole have advanced quicker than many believed possible. One of the main limiting factors of Moravec's Paradox is how difficult it is to give computers those sensory + perception skills. It has been near-impossible, until recently, to give them the intelligence to then generate actions based off those more complex data inputs.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Just as Sora is generating hyper-realistic videos from scratch, robots have now been able to achieve similar milestones generating actions and planning. With additional features for mobility, these robots are becoming independent in the spaces around them, ready to work with us and understand the world. And like the AIoT, these robots will likely be able to share learnings among themselves one day, making them interconnected, learning from each other as much as they are learning from us.</Typography>
        <Typography variant="body1" gutterBottom>
          Stay tuned for my next post where I find out more about AI in robotics and what's been going on lately.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Thanks for reading!
        </Typography>
        <Typography variant="subtitle2" gutterBottom style={{ color: 'gray', marginTop: '24px' }}>
          Honorable Mention 
          <div class='iframe-container' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <iframe class='responsive-iframe' src="https://www.youtube.com/embed/KKNCiRWd_j0?si=EmSueg9q0wUwh8vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </Typography>
        
        </Typography>
      </div>
    )
  },
  {
    date: '9-3-2024',
    route: 'introduction',
    content: (
        <div>
        <Typography variant="h4" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
              Introduction
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              By:  <Link 
              title='https://www.linkedin.com/in/luke-nispel' 
              style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}
              onClick={() => window.open('https://www.linkedin.com/in/luke-nispel/', '_blank')}>
                Luke Nispel
              </Link> - Sept. 3rd, 2024 
            </Typography>

            <Typography variant="body1" gutterBottom style={{
              paddingTop: '12px'
            }}>
              Hello and welcome to Flip The Burger! 🍔
            </Typography>
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
            About Me
            </Typography>
            <Typography variant="body1" gutterBottom>
              My name is Luke. I am currently a software engineer working for <Link title='http://www.procore.com' onClick={() => window.open('http://www.procore.com', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Procore Technologies, Inc.</Link> helping lead the frontend team into the future.
            </Typography>
            <Typography variant="body1" gutterBottom>
              My focus has primarily been in React but I have stepped into other FE frameworks, languages, some backend code but a large chunk of it has been web development.
            </Typography>
            <Typography variant="body1" gutterBottom>
              I have next to no experience with robotics, have not worked with python much and have never ran a blog before but with this project, I hope to get better at all three.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
            Purpose
            </Typography>

            <Typography variant="body1" gutterBottom>
              The end goal of this project is to enable a robot to cook a burger from start to finish. It should not need to be instructed to flip the burger. It should deduce that on its own based on historical data of cooking a burger with human demonstrators.
            </Typography>
            <Typography variant="body1" gutterBottom>
              This will be done using <Link title='https://mobile-aloha.github.io/' onClick={() => window.open('https://mobile-aloha.github.io/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>
              Mobile Aloha from Stanford Robotics.</Link> The journey will be about documenting how that process is done and hopefully sharing learnings that enable more developers to dive into this open-source code.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Since it is a bit of a leap to get to there from scratch today, on top of the full mobile robot itself costs roughly $30,000, the project will start by working with a single programmable arm.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Part of the process will be to set up and go through a series of trainings with the arm to be able to:
            <br/>
            👉 Understand the robot and how the pieces move/work together
            <br/>
            👉 Learn how the programs interact with each other 
            <br/>
            👉 What challenges the arm runs into that can be applied to later on in the project
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
            Expectations & Format
            </Typography>
            <Typography variant="body1" gutterBottom>
              A burger vision tomorrow does not mean that there cannot be a differnt vision today. 
            </Typography>
            <Typography variant="body1" gutterBottom>
              Thanks to <Link title='https://cityheightsba.org/business-spotlight-ryan-bros-coffee/' onClick={() => window.open('https://cityheightsba.org/business-spotlight-ryan-bros-coffee/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Maxine Zepeda</Link>, owner of <Link title='https://g.co/kgs/DQQtf4e' onClick={() => window.open('https://g.co/kgs/DQQtf4e', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Ryan Bros. City Heights Coffeehouse</Link>, the project will be able to use the space to test the different abilities of the arm while developing before handling the burger becomes a reality.
            </Typography>
            <Typography variant="body1" gutterBottom>
              The first milestone the project will shoot for is to use the arm to make a cup of coffee without the use of <Link title='https://mobile-aloha.github.io/' onClick={() => window.open('https://mobile-aloha.github.io/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>
              Mobile Aloha.</Link> 
            </Typography>
            <Typography variant="body1" gutterBottom>
              Once that is hit, more complex tasks can be taken on and then tying in the program will become a focus.
            </Typography>
            <Typography variant="body1" gutterBottom>
              I plan to post weekly blog posts here and videos on <Link title='https://www.youtube.com/@fliptheburger-ai' onClick={() => window.open('https://www.youtube.com/@fliptheburger-ai')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Youtube</Link> on Thursday evenings.
            </Typography>
            <Typography variant="h6">
              Thanks for reading and stay up to date by <Link title='/subscribe' onClick={() => window.open('/subscribe')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>subscribing</Link> to the weekly email newsletter!
            </Typography>
        </div>
    )
  },
];

export default posts;